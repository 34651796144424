/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-#0974bbuced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
}

body {
  background: #0974bb;
}

.bottom-div {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.container {
  max-width: 100vw;
  margin: 0 auto;
  /* height: auto; */
}

#header {
  background: white;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

#header-img {
  margin-left: 20px;
  float: left;
  height: 80px;
}

nav ul {
  overflow: hidden;
  clear: both;
  margin: 0;
  padding: 0;
  max-height: 0;
  transition: max-height .2s ease-out;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  font-size: 1.15;
  text-decoration: none;
  color: black;
  padding: 10px;
}

a.highlight {
    display: block;
  font-size: 1.15;
  text-decoration: none;
  color: black;
  padding: 10px;
}

/* menu icon */

.menu {
  margin-left: 15px;
}


.loading {
  /* display: flex;
  justify-content: center; 
  align-items: center;       */
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 350px;
}

#header .menu-icon {
  cursor: pointer;
  padding: 28px 20px;
  user-select: none;
  float: right;
}

#header .menu-icon .nav-icon {
  background: #333;
  display: block;
  height: 3px;
  position: relative;
  top: 10px;
  transition: background .2s ease-out;
  width: 30px;
}

#header .menu-icon .nav-icon:before,
#header .menu-icon .nav-icon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

#header .menu-icon .nav-icon:before {
  top: 6px;
}

#header .menu-icon .nav-icon:after {
  top: -6px;
}

/* menu btn */

#header .menu-btn {
  display: none;
}

#header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

#header .menu-btn:checked ~ .menu-icon .nav-icon {
  background: transparent;
}

#header .menu-btn:checked ~ .menu-icon .nav-icon:before {
  transform: rotate(-45deg);
  top:0;
}

#header .menu-btn:checked ~ .menu-icon .nav-icon:after {
  transform: rotate(45deg);
  top:0;
}


main {
  display: flex;
  flex-flow: column-reverse;
  color: white;
  width: 100%;
  margin: 0;
  padding-top: 70px;
  /* height: 100%; */
}

.hero {
  text-align: center;
  margin-top: 50px;
}

/* .hero-title-primary {
  font-weight: 700;
  font-size: 10vmin;
  line-height: 58px;
} */

.hero-title-sub {
  margin: 0.2em 0 1em 0;
  font-weight: 300;
  font-size: 4.6vmin;
}

button {
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
}

button:hover {
  background-color: black;
  color: white;
}

figure {
  text-align: center;
}

figure svg {
  margin: 3em auto 0;
  width: 80vw;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.video-content {
  padding-top: 80px;
  display: flex;
  align-content: center;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
  color: #0974bb;
  background-color: white;
}

.video-title {
  font-size: 35px;
  
}

.video-subtitle {
  margin-top: -10px;
  color: #d3d3d3;
  margin-bottom: 20px;
}

iframe {
   height: 50vw;
    width: 91vw;
}

.three-dots {
  text-align: center;
}

.three-dots span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #0974bb;
  display: inline-block;
  margin: 30px 5px;
}

.content {
  padding: 10px 38px 10px 38px;
  background: white;
}

.inner-content {
  padding-top: 50px;
}

.inner-content-text {
  padding-right: .78125rem;
  padding-left: .78125rem;
  padding-bottom: 40px;
}

.inter-content-subtitle {
  position: relative;
  display: inline-block;
  padding-left: 40px;
  font-size: .875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: .10938rem;
  color: #828282;
}

.inter-content-subtitle::before {
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    width: 25px;
    height: 5px;
    content: "";
    background-color: #0974bb;
    opacity: 0.7;
}

.inner-content-title {
  margin-top: 25px;
  margin-right: 60px;
  font-size: 2.125rem;
  font-weight: 700;
  color: #0974bb;
}

.inner-content-para {
  margin-top: 25px;
  margin-right: 60px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: .04375rem;
  font-style: normal;
  font-weight: 400;
  color: #4c4c4c;
}

.inner-image-container {
  position: relative;
  padding-right: .78125rem;
  padding-left: .78125rem;
  flex: 0 0 100%;
  max-width: 100%;
  height: auto;
}


.inner-content-image {
  position: relative;
  height: 350px;
}

.section-images {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pricing-container {
  padding: 150px 38px 10px 38px;
  background: white;
  display: flex;
  flex-direction: column;
}

.pricing-title {
  margin-bottom: 50px;
  font-size: 23px;
  color: black;
  align-self: center;
}

.pricing-title > h2 {
  font-weight: 600;
}

.flex-container {
  max-width: 80vw;
  margin: 0 auto;
  display: flex;
  padding: 1em;
}

.flex-item {
  flex: 1;
  width: 250px;
  border: 1px solid rgba(0,0,0,.05);
  border-radius: 8px;
}

.flex-item:not(:last-child) {
  margin-right: 2em;
}

.package {
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: 0.25s;
  display: flex;
  flex-direction: column;
}

.package:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
}

.package .header {
  color: black;
  border-bottom: 5px #0974bb solid;
  font-size: 1.6em;
  font-weight: 600;
}

.package li {
  color: rgba(55, 53, 47, 0.6);
  background-color: #fff;
  padding: 1.2em;
}

li.features:before {
  content: '✓';
  padding-right: 5px;
}

li.features {
  text-align: left;
  padding: 10px;
}

li.first-feat {
  margin-top: 30px;
}

.package .gray {
  background-color: #f7f7f7;
  color: black;
  font-weight: 600;
  font-size: 40px;
  padding: .8em;
  line-height: 80%;
}

.dolla {
  top: -.2em;
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: initial;
}

.month {
  font-size: 16px;
  font-weight: normal;
}

.pricing-button {
  background-color: white;
  border: 1px solid #0974bb;
  border-radius: 2em;
  color: #0974bb;
  cursor: pointer;
  padding: .75em 1.5em;
  font-size: 1em;
}


.subscribe-container {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 80px;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5em;
  min-width: 350px;
}


#form {
  /* display: flex;
  flex-direction: row; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}

.ccForm {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;

}

.conditions {
  color: #0974bb;
  padding: .75em 0.3em;
}
.p-conditions {
  color: #0974bb;
  padding-right: 1.5em;
  padding-left: 1.5em;
  padding-top: 1.5em;
  font-size: 12px;
  font-weight: 450;
  max-width: 350px;
  text-align: justify;
}
.ccField {
  width: 250px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #0974bb;
  text-decoration: none;
  margin-bottom: 10px;
  padding-left: 10px;
}

#submit {
  width: 250px;
  height: 40px;
  border: none;
  background: #0974bb;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
}

input[type="email"] {
  padding-left: 10px;
}

.hero-top {
  /* margin-bottom: 20px; */
  color: #0974bb;

}



@media only screen and (max-width: 984px) {
  .flex-container {
    flex-wrap: wrap;
  }

  .flex-item {
    flex: 0 0 100%;
    margin-bottom: 1em;
    width: 100%;
  }

  .package:hover {
    box-shadow: none;
    transform: none;
  }

  .pricing-button {
    padding: .75em 1.5em;
  }
}


/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@media screen and (min-width: 820px) {
  .container {
    max-width: 80vw;
  }
  
  .menu-container {
    max-width: 100vw;
  }
  

  
  .menu {
  margin-top: 20px;
  }
  
  nav {
    margin-right: -100px;
  }
  
  nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  
  nav ul li {
    float: left;
    list-style: none;
    padding: 0.5em 1em;
  }
  nav ul li a {
    font-size: 1em;
    text-decoration: none;
    color: #333;
    padding: 0;
  }
  
  a.highlight {
    font-size: 1em;
    text-decoration: none;
    color: #333;
    padding: 0.5em 1em;
  }
  
  #header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  #header .menu-icon {
    display: none;
  }
  
  .highlight {
    border: 1px solid #333;
    border-radius: 50px;
  }
  
  .highlight:hover,.special:hover {
    background: black;
    color: white;
    border-bottom: none;
  }
  
  main {
    flex-flow: row;
  }
  
  .hero {
    margin-top: 90px;
    text-align: left;
  }
  
  .hero h1 {
    font-size: 3em;
    line-height: 55px;
  }
  
  .hero p {
    font-size: 1.3em;
    margin: 0.5em 0 1em 0;
    padding: 0;
  }
  
  figure svg {
    width: 500px;
    margin: 0;
  }
  
  iframe {
   height: 30vw;
    width: 55vw;
}
  
  .content {
  padding: 0 68px 0 68px;
  background: white;
}

  .container__ImageRight {
    order: 2;
    margin-left: 8.33333%;
  }
  
  .content__ImageRight {
    left: 0;
  }
  
  .container__ImageLeft {
    order: 1;
  }
  
  .content__ImageLeft {
    right: 0;
  }
  
  .content__TextLeft {
    order: 1;
  }
  
  .content__TextRight {
    margin-left: 8.33333%;
    order: 2;
  }

.inner-content {
  max-width: 80vw;
  padding-top: 100px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.inner-content-text {
  padding-right: .9375rem;
  padding-left: .9375rem;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  display: flex;
  min-height: 550px;
  padding-top: 60px;
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: center;
}

.inner-content-title {
  margin-top: 25px;
  margin-right: 60px;
  font-size: 2.125rem;
  font-weight: 700;
  color: #0974bb;
}

.inner-content-para {
  margin-top: 25px;
  margin-right: 60px;
  font-size: 1.125rem;
  line-height: 1.875rem;
  letter-spacing: .04375rem;
  font-style: normal;
  font-weight: 400;
  color: #4c4c4c;
}

.inner-image-container {
  position: relative;
  padding-right: .78125rem;
  padding-left: .78125rem;
  flex: 0 0 50%;
  max-width: 50%;
  height: auto;
}


.inner-content-image {
  position: absolute;
  top: 0;
  width: calc(100% - 12.5px);
  height: 100%;
}

.section-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
}


@media (max-width: 768px) {
  .waves {
    height: 90px;
    min-height: 90px;
  }
  h1 {
    font-size: 20px;

  }
}





